import React, { Fragment, Component } from 'react';
import './App.css';

const customers = [
    "fibras_nacionales.png",
    "cridesa.png",
    "descalzi.png",
    "publiradio.png",
    "santa_priscila.png",
    "ransa.png",
    "propalcol.png",
    "star_office.png",
    "fresenius_kabi.png",
]

const services = [
    {
        image: "/assets/services/group1.png",
        title: `<span class="text-yorange font-bold">Instalación</span> eléctrica`,
    },
    {
        image: "/assets/services/group2.png",
        title: `<span class="text-yorange font-bold">Instalación</span> de luminarias`,
    },
    {
        image: "/assets/services/group3.png",
        title: `<span class="text-yorange font-bold">Instalación</span> de acometidas`,
    },
    {
        image: "/assets/services/group4.png",
        title: `<span class="text-yorange font-bold">Instalación</span> de tubería rígida`,
    },
    {
        image: "/assets/services/group5.png",
        title: `<span class="text-yorange font-bold">Instalación</span> de tubería EMT`,
    },
    {
        image: "/assets/services/group6.png",
        title: `<span class="text-yorange font-bold">Instalación</span> eléctrica`,
    },
    {
        image: "/assets/services/group7.png",
        title: `<span class="text-yorange font-bold">Diseño</span> eléctrico`,
    },
    {
        image: "/assets/services/group8.png",
        title: `<span class="text-yorange font-bold">Mantenimiento</span> de tableros`,
    }
]

const BARS3 = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg>;

const CLOSE = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>;

function StyledButton({ children, onClick, className }) {
    return (
        <button className={`outline outline-1 outline-[#767676] tracking-[5%] font-medium py-2 px-4 rounded styled-button ${className}`} onClick={onClick}>
            {children}
        </button>
    );
}

function scrollIntoView(id) {
    const el = document.getElementById(id);
    const y = el.getBoundingClientRect().top + window.pageYOffset - 100;

    window.scrollTo({ top: y, behavior: 'smooth' });

    closeSidebar();
}

function closeSidebar() {
    const sidebar = document.getElementById("sidebar");
    if (sidebar) {
        sidebar.style.display = "none";
    }
}


function displaySidebar() {
    const sidebar = document.getElementById("sidebar");
    if (sidebar) {
        sidebar.style.display = "block";
    }
}

function Navbar() {

    return (
        <nav className="bg-gradient-to-r w-full from-matisse/10 from-5% to-white/30 trispace text-ebony backdrop-blur-[2px] fixed z-50">
            <div className="flex flex-row w-full">
                <div className="grow">
                    <img src="/assets/logo.png" className="scale-75" alt="logo" />
                </div>
                <div className="hidden lg:flex flex-row space-x-8 p-10 items-center">
                    <p onClick={() => scrollIntoView("home")} className="text-center cursor-pointer">inicio</p>
                    <p onClick={() => scrollIntoView("about")} className="text-center cursor-pointer">sobre nosotros</p>
                    <p onClick={() => scrollIntoView("services")} className="text-center cursor-pointer">nuestro trabajo</p>
                    <p onClick={() => scrollIntoView("customers")} className="text-center cursor-pointer">nuestros clientes</p>
                    <p onClick={() => scrollIntoView("contact")} className="text-center cursor-pointer">contacto</p>
                </div>
                <div className="lg:hidden flex items-center ">
                    <div className="inline-block h-0 pb-[100%] w-20 text-white pr-5 pt-3">
                        <div className="w-full aspect-square flex items-center justify-center">
                            <div className="bg-ebony-500 p-1 rounded" onClick={displaySidebar}>
                                {BARS3}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NavigationSidebar />
        </nav>
    );
}

function NavigationSidebar() {
    return (
        <div id="sidebar" className="fixed top-0 left-0 h-screen w-screen flex flex-col items-center justify-center backdrop-blur-lg bg-ebony/50 text-white text-3xl" style={{ display: "none" }}>
            <div className="flex flex-col w-full text-right p-10 font-bold h-full divide-y-[1px]">
                <p onClick={() => scrollIntoView("home")} className="py-5 cursor-pointer">inicio</p>
                <p onClick={() => scrollIntoView("about")} className="py-5 cursor-pointer">sobre nosotros</p>
                <p onClick={() => scrollIntoView("services")} className="py-5 cursor-pointer">nuestro trabajo</p>
                <p onClick={() => scrollIntoView("customers")} className="py-5 cursor-pointer">nuestros clientes</p>
                <p onClick={() => scrollIntoView("contact")} className="py-5 cursor-pointer">contacto</p>
                <span className="grow" />
                <div className="flex justify-end">
                    <div className="p-1 rounded" onClick={closeSidebar}>
                        {CLOSE}
                    </div>
                </div>
            </div>
        </div>
    );
}

function LandingHeader() {
    return (
        <header id="home" className="flex justify-between items-center">
            {/* <img src="./logo.png" className="scale-75" alt="logo" /> */}
            <div className="w-full h-screen bg-cover bg-no-repeat bg-center" style={{ backgroundImage: "url('/assets/landing.png')" }}>
                <div className="absolute lg:inset-x-0 lg:bottom-0 p-20 lg:max-w-[70%] h-full lg:h-auto flex flex-col justify-center items-center space-y-5">
                    <h1 className="text-3xl lg:text-5xl font-medium text-center">Diseño eléctrico, instalación y mantenimiento para sectores <span className="font-bold">comerciales</span> e industriales.</h1>
                    <div>
                        <StyledButton onClick={() => scrollIntoView("contact")}>
                            <span className="text-2xl">
                                Contáctanos
                            </span>
                        </StyledButton>
                    </div>
                </div>
            </div>
        </header>
    )
}

function SectionTitle({ children }) {
    return (
        <div className="mb-5">
            <h2 className="text-4xl font-bold trispace text-yorange text-center">{children}</h2>
        </div>
    );
}

// function FullWidthSection({ id, title, children }) {
//     return (
//         <div id={id} className="w-full flex flex-col items-center py-5 px-5 lg:py-10 lg:px-20">
//             <div className="flex justify-center">
//                 <SectionTitle>{title}</SectionTitle>
//             </div>
//             {children}
//         </div>
//     );
// }

function Section({ id, title, children }) {
    return (
        <div id={id} className="w-full flex flex-col items-center py-5 px-5 lg:py-10 lg:px-20">
            <div className="container flex justify-center">
                <SectionTitle>{title}</SectionTitle>
            </div>
            <div className="container">
                {children}
            </div>
        </div>
    );
}

// Get only the inner text from html from an unparsed string.
// Example: hello <strong>world</strong> -> "hello world"
function flattenUnparsedHtml(html) {
    const temp = document.createElement("p");
    temp.innerHTML = html;
    return temp.innerText;
}

function ServiceCard({ image, title }) {
    return (
        <div className="flex justify-center items-center text-white p-2">
            <div className="bg-ebony p-1 rounded-[16px] max-w-[200px] h-full w-full flex flex-col">
                <div className="aspect-square rounded-xl bg-white bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${image})` }}>
                    <img src={image} alt={flattenUnparsedHtml(title)} className="hidden" />
                </div>
                <div className="py-1 px-2 grow text-center flex flex-col items-stretch justify-center">
                    <p dangerouslySetInnerHTML={{ __html: title }} />
                </div>
            </div>
        </div>
    );
    // return (
    //     <div className="flex flex-col justify-center items-center lg:p-0">
    //         <div className="max-w-[200px] aspect-square flex items-center justify-center bg-ebony">
    //             <img className="rounded-xl border border-red-400" src={image} alt="Test" />
    //         </div>
    //         <div className="text-center">
    //             <h1>Lorem ipsum</h1>
    //         </div>
    //     </div>
    // );
}

const TIMEOUT = 5000;

class Carousel extends Component {

    constructor(props) {
        super(props);
        this.state = { current: 0 };

        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
    }

    next() {
        this.setState({ current: (this.state.current + 1) % this.props.children.length });
        // Reset the timer
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.next(), TIMEOUT);
    }

    prev() {
        this.setState({ current: (this.state.current - 1 + this.props.children.length) % this.props.children.length });
        // Reset the timer
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => this.next(), TIMEOUT);
    }

    componentDidMount() {
        this.timeout = setTimeout(() => this.next(), TIMEOUT);
    }

    render() {
        const { children, className } = this.props;
        const childrenArray = React.Children.toArray(children);
        return (
            <div className={className}>
                <div className="w-full">
                    {childrenArray.map((child, index) => (
                        <div key={index} className={`flex-1 transition-all ${index === this.state.current ? "opacity-100" : "hidden opacity-0"}`}>
                            {child}
                        </div>
                    ))}
                </div>
                <div className="w-full">
                    <div className="absolute top-1/2 flex justify-center z-10 w-full">
                        <div className="flex flex-row w-full text-white">
                            <div className="p-2 cursor-pointer select-none flex-none" onClick={this.prev}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                                </svg>
                            </div>
                            <span className="grow"></span>
                            <div className="p-2 cursor-pointer select-none flex-none" onClick={this.next}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

}

function ImageCarousel({ className }) {
    return (
        <div className={className}>
            <Carousel className="aspect-video max-h-[300px] relative">
                {/* Imagen 1 */}
                <div className="aspect-video w-full">
                    <div className="flex-1">
                        <img src="/assets/carousel/img1.png" alt="carousel" className="rounded-2xl" />
                    </div>
                    <div className="absolute -bottom-[10px] -right-[15px] 2xl:-bottom-[20px] 2xl:-right-[30px] bg-matisse trispace text-white text-sm 2xl:text-lg px-4 py-1 z-10 shadow-lg shadow-black/30 text-center max-w-[90%]">
                        <p>Expertos en diseño y mantenimiento eléctrico</p>
                    </div>
                </div>
                {/* Imagen 2 */}
                <div className="aspect-video w-full">
                    <div className="flex-1">
                        <img src="/assets/carousel/img2.png" alt="carousel" className="rounded-2xl" />
                    </div>
                    <div className="absolute -bottom-[10px] -right-[15px] 2xl:-bottom-[20px] 2xl:-right-[30px] bg-matisse trispace text-white text-sm 2xl:text-lg px-4 py-1 z-10 shadow-lg shadow-black/30 text-center max-w-[90%]">
                        <p>Cumpliendo con los mejores estándares de la industria</p>
                    </div>
                </div>
            </Carousel>
        </div>
    );
}

function Timeline({ years, labels }) {
    return (
        <div className="flex flex-row items-center">
            {years.map((year, index) => (
                <Fragment key={index}>
                    <div className="flex flex-col items-center has-tooltip">
                        <span className='tooltip rounded shadow-lg px-2 py-1 bg-[#D9D9D9]/50 text-black text-sm -mt-8 text-center'>{labels[index]}</span>
                        <div className="w-10 h-10 bg-matisse text-white flex items-center justify-center rounded-full p-8" style={{ zIndex: 1 }}>
                            <p>{year}</p>
                        </div>
                    </div>
                    {index < years.length - 1 && (
                        <div className="flex-1 w-10 h-4 bg-matisse" style={{ marginLeft: '-5px', marginRight: '-5px', zIndex: 0 }}></div>
                    )}
                </Fragment>
            ))}
        </div>
    );
}

function Footer() {
    return (
        <div className="flex bg-ebony text-white justify-center">
            <div className="hidden lg:flex container flex-col lg:flex-row">
                <div className='px-10 py-5 basis-1/4 flex items-center justify-center'>
                    <div className="flex flex-col">
                        <img src="/assets/logo.png" alt="logo" className="w-40" />
                        <h1 className="font-bold">Construcciones Eléctricas Alvarado S.A.</h1>
                        <br />
                        <p>Av. Francisco de Orellana, Cdla. Los Vergeles Norte Mz. 168 Solar 13</p>
                        <br />
                        <a href="https://navvi.studio/"><img src="/assets/PBN.svg" alt="Site Powered by navvi" className="w-28" /></a>
                    </div>
                </div>
                <div className='basis-1/2 bg-white relative'>
                    <iframe
                        className="border-0 h-full w-full absolute inset-0 top-0 left-0"
                        title="Ubicación"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5425.693238865979!2d-79.90441615819502!3d-2.090012801004879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d12945b7051a1%3A0x6a601f1c33f90114!2sLos%20Vergeles%2C%20Guayaquil!5e0!3m2!1sen!2sec!4v1710285870811!5m2!1sen!2sec"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </div>
                <div className='px-10 py-5 basis-1/4 flex items-center justify-center'>
                    <div className="flex flex-col">
                        <h1 className="font-bold">Medios de contacto:</h1>
                        <br />
                        <h2 className="font-bold">Teléfonos:</h2>
                        <h2>098 416 7107</h2>
                        <h2>098 499 8820</h2>
                        <br />
                        <h2 className="font-bold">Correo:</h2>
                        <h2>info@celalvasa.com</h2>
                    </div>
                </div>
            </div>
            <div className="lg:hidden w-full">
                <div className='px-10 py-5 basis-1/4 flex flex-col items-center justify-center'>
                    <div className="flex flex-col">
                        <img src="/assets/logo.png" alt="logo" className="w-40" />
                        <h1 className="font-bold">Construcciones Eléctricas Alvarado S.A.</h1>
                        <br />
                        <p>Av. Francisco de Orellana, Cdla. Los Vergeles Norte Mz. 168 Solar 13</p>
                        <hr className="my-5" />
                        <h1 className="font-bold">Medios de contacto:</h1>
                        <br />
                        <h2 className="font-bold">Teléfonos:</h2>
                        <h2>098 416 7107</h2>
                        <h2>098 499 8820</h2>
                        <br />
                        <h2 className="font-bold">Correo:</h2>
                        <h2>info@celalvasa.com</h2>
                        <br />
                        <a href="https://navvi.studio/"><img src="/assets/PBN.svg" alt="Site Powered by navvi" className="w-32" /></a>
                    </div>
                </div>
                <div className="h-40">
                    <iframe
                        className="w-full"
                        title="Ubicación"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5425.693238865979!2d-79.90441615819502!3d-2.090012801004879!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x902d12945b7051a1%3A0x6a601f1c33f90114!2sLos%20Vergeles%2C%20Guayaquil!5e0!3m2!1sen!2sec!4v1710285870811!5m2!1sen!2sec"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        height={160}
                    />
                </div>
            </div>
        </div>

    );
}

function App() {
    return (
        <div>
            <Navbar />
            <LandingHeader />
            <div className="pt-10">
                <Section id="about" title="Sobre Celalva S.A.">
                    <div className="flex flex-col space-y-14 pt-10">
                        <div className="flex flex-col xl:flex-row space-y-5 xl:space-y-0 items-stretch">
                            <div className="flex-col flex-1 text-justify xl:flex justify-center xl:items-end">
                                <div className="w-full flex items-center justify-center 2xl:max-w-[75%]">
                                    <div className="hidden xl:inline h-full pt-4">
                                        <div className="xl:border-l xl:border-y xl:border-ebony h-full pr-10 rounded-l-xl"></div>
                                    </div>
                                    <div className="flex flex-col xl:items-center xl:justify-center space-y-5 text-xl">
                                        <div className="flex flex-row w-full justify-center items-center">
                                            <div className="xl:border-t h-0 border-ebony grow" />
                                            <h1 className="text-2xl font-bold text-ebony px-3 text-center">¿Quiénes somos?</h1>
                                            <div className="h-0 grow" />
                                        </div>
                                        <div className="flex flex-col space-y-6">
                                            <p>Una <span className="text-matisse">empresa familiar</span> dedicada a las instalaciones y mantenimiento eléctrico para el sector comercial, industrial y urbano. </p>
                                            <p>Construcciones Eléctricas Alvarado S.A. (CELALVA S.A.) cuenta con un <span className="text-matisse">staff capacitado</span> y con experiencia en diferentes campos.</p>
                                            <p>Con oficinas en Guayaquil, Celalva S.A. <span className="text-matisse">se moviliza a diferentes provincias</span> y lugares del país para realizar obras y proyectos.</p>
                                        </div>
                                        <div className="flex flex-row w-full justify-center items-center">
                                            <div className="xl:border-t h-0 border-ebony grow" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 flex justify-center xl:inline px-16">
                                <div className="flex items-center h-full">
                                    <ImageCarousel className="" />
                                </div>
                            </div>
                            <div className="flex-col flex-1 text-justify xl:flex justify-center">
                                <div className="w-full flex items-center justify-center 2xl:max-w-[75%]">
                                    <div className="flex flex-col xl:items-center xl:justify-center space-y-5 text-xl">
                                        <div className="flex flex-row w-full justify-center items-center">
                                            <div className="h-0 grow" />
                                            <h1 className="text-2xl font-bold text-ebony px-3 pt-10 xl:py-0 text-center">Cultura de trabajo</h1>
                                            <div className="xl:border-t h-0 border-ebony grow" />
                                        </div>
                                        <div className="flex flex-col space-y-6">
                                            <p>Realizar trabajos que cumplan con las normas y estándares de seguridad ambiental y calidad nacionales e internacionales.</p>
                                            <p>Nuestra visión es ser una empresa de vanguardia, que brinde soluciones integrales en las áreas de ingeniería eléctrica.</p>
                                            <p>Nuestra misión es seguir cumpliendo en calidad, tiempos de entrega y términos contractuales con todos nuestros clientes.</p>
                                        </div>
                                        <div className="flex flex-row w-full justify-center items-center">
                                            <div className="xl:border-t h-0 border-ebony grow" />
                                        </div>
                                    </div>
                                    <div className="hidden xl:inline h-full pt-4">
                                        <div className="xl:border-r xl:border-y xl:border-ebony h-full pr-10 rounded-r-xl"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
                <div className="bg-ebony text-white">
                    <div className="bg-white h-6 xl:h-16 rounded-b-[60px] flex justify-center pt-20 xl:pt-40">
                        <div className="flex flex-row items-end justify-center container">
                            <div className="hidden lg:inline grow"></div>
                            <div className="grow flex justify-center">
                                <div className="bg-ebony px-4 py-1 rounded-t-xl text-3xl font-semibold">
                                    <span>Historia</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="container px-5 flex flex-col-reverse lg:flex-row">
                            <div className="grow basis-1/2 flex items-end justify-center">
                                <div className="2xl:relative 2xl:-mt-40 max-w-[500px] lg:max-w-full">
                                    <img src="/assets/people.png" alt="Celalva S.A." className="" />
                                </div>
                            </div>
                            <div className="grow basis-1/2 flex items-center justify-center py-5">
                                <div className="md:max-w-[70%] 2xl:max-w-[60%] flex flex-col md:items-center md:justify-center space-y-10 text-justify py-10">
                                    <p className="text-xl">Nace del espíritu emprendedor que caracteriza a su dueño Carlos Alvarado, quién junto a su esposa decidió crear una empresa para brindar servicios eléctricos a la industria. Poco a poco, empresas comenzaron a contratarlos para pequeños proyectos que fueron creciendo, y con ello el personal necesario para llevarlos acabo. En la actualidad, la empresa cuenta con un staff para cada área que el trabajo pueda necesitar.</p>
                                    <Timeline years={[1999, 2008, 2010, 2012]} labels={["Creación de la empresa", "Expansión de la empresa", `Premio Ekos "PYME"`, "Nuevas oficinas"]} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="services" className="bg-ebony">
                    <div className="bg-white rounded-t-[60px] py-20 px-5 flex items-center justify-center">
                        <div className="container">
                            <SectionTitle>Nuestros servicios</SectionTitle>
                            <div className="w-full grid grid-cols-2 md:grid-cols-4 flex-wrap max-w-[1800px] pt-3">
                                {services.map((o, i) => {
                                    return (<ServiceCard key={`service-${i}`} image={o.image} title={o.title} />);
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <Section id="customers" title="Nuestros clientes">
                    <div className="flex flex-row flex-wrap">
                        {customers.map((customer, index) => {
                            return (
                                <div key={index} className="flex justify-center items-center basis-1/3">
                                    <img className="aspect-[424/263]" src={`/assets/customers/${customer}`} alt="customer" />
                                </div>
                            );
                        })}
                    </div>
                </Section>
                <div id="contact" className="bg-top bg-cover" style={{ backgroundImage: 'url(/assets/contact_bg.png)' }}>
                    <div className="bg-white rounded-b-[60px] py-5">
                        <SectionTitle>Contáctanos</SectionTitle>
                    </div>
                    <div className="flex items-center justify-center">
                        <div className="container flex flex-col lg:flex-row md:py-4 lg:py-8">
                            <div className="basis-1/2 flex justify-center items-center">
                                {/* <img className="w-[60%] lg:aspect-[789/692] lg:w-auto lg:h-auto" src={"/assets/contact.png"} alt="map" /> */}
                            </div>
                            <div className="flex items-center justify-center flex-col basis-1/2 text-white">
                                <div className="max-w-96 text-center space-y-5 py-16">
                                    <h1 className="font-bold text-3xl">¿Dudas, sugerencias o cotizaciones?</h1>
                                    <h2 className="text-2xl">Escríbenos y te responderemos cualquier inquietud.</h2>

                                    {/* Nombre, correo, opcion, descripcion */}
                                    <form className="flex flex-col space-y-5 w-full text-black" action="https://formsubmit.co/info@celalvasa.com" method="POST">
                                        <input type="text" name="Nombre" placeholder="Nombre" className="px-2 py-1 border-2 border-matisse rounded-lg w-full" required />
                                        <input type="email" name="Email" placeholder="Correo" className="px-2 py-1 border-2 border-matisse rounded-lg w-full" required />
                                        <div className="select-wrapper border-2 border-matisse rounded-lg w-full">
                                            <select name="Tipo de mensaje" className="w-full px-2 py-1 rounded-lg bg-white appearance-none" required>
                                                <option value="cotizacion">Cotizacion</option>
                                                <option value="duda">Duda</option>
                                                <option value="sugerencia">Sugerencia</option>
                                            </select>
                                        </div>
                                        <textarea name="Descripción" placeholder="Descripción" className="px-2 py-1 border-2 border-matisse rounded-lg w-full" required></textarea>
                                        <input type="hidden" name="_template" value="box"></input>
                                        <input type="hidden" name="_subject" value="Nueva solicitud de contacto"></input>
                                        <input type="hidden" name="_next" value="https://celalvasa.com/"></input>
                                        <StyledButton className="text-white outline-ebony">Enviar</StyledButton>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default App;